import { AppBar, Box, CircularProgress, Toolbar, Button, Typography, styled, InputBase, IconButton } from "@mui/material";
import "../css/App.css";
import KeyIcon from "@mui/icons-material/Key";
import SearchIcon from "@mui/icons-material/Search";
import { SearchIconStyle, Search, NavigateToNFTList } from './nftlist_header_bar'
import { useNavigate } from 'react-router-dom';
import { padding } from "@mui/system";
import { FaDiscord } from "react-icons/fa";
import TwitterIcon from '@mui/icons-material/Twitter';
export function HomePage() {
    //for resizing the text
    let topTextSize: String = "4em";
    let descriptionTextSize: String = "1.1875rem";
    let navigate = useNavigate();

    if (window.innerWidth < 900) {
        topTextSize = "2.8em";
        descriptionTextSize = "0.9rem";
    } else {
        topTextSize = "4em";
        descriptionTextSize = "1.1875rem";
    }

    return (<div className="wh-weedheads-text">
        <Box>
            <Typography fontSize={topTextSize.toString()} fontWeight={700} fontFamily={"sans-serif"} textAlign={"center"}
                style={{ textShadow: "1px 1px #006400" }}
            >
                Weedheads NFT<br />
                Rarity Tool

            </Typography>
            <div style={{
                margin: "0 auto",
                position: 'relative',
                alignContent: 'center',
                justifyContent: 'center',
            }}>
                <p className="wh-home-description" style={{

                }}>Weedheads Rarity tool can be used to check the rarity of all your owned Weedheads NFTs. There are <strong>712</strong> Weedheads NFT minted in total
                from two batches. 

                    {/* You can also see your total percentage of profit share that you are going to own in our NFTease platform. */}
                </p>
            </div>
        </Box>
        <Box sx={{
            position: 'static',
            alignItems: 'center',
            display: 'flex',
            marginTop: '40px',
            marginBottom: "15%"
        }}>
            <Box sx={{
                flexGrow: 1,
            }} />
            <Search id='searchList'>
                <div className='wh-key-icon'>
                    <KeyIcon />
                </div>
                <StyledInputBase
                    placeholder="Wallet Public Address"
                    inputProps={{ 'aria-label': 'search' }}
                    onKeyDown={(event: any) => {
                        if (event.key == 'Enter') {
                            NavigateToNFTList("searchList", navigate);
                        }

                    }}
                />
            </Search>
            <Box sx={{ display: { md: 'flex' } }}>
                <SearchIconStyle onClick={() => {
                    NavigateToNFTList("searchList", navigate);
                }
                }>
                    <div className='wh-search-icon' onClick={() => {
                        NavigateToNFTList("searchList", navigate);}}>
                        <SearchIcon />
                    </div>
                </SearchIconStyle>
            </Box>
            <Box sx={{ flexGrow: 1 }} />
        </Box>

        {/* <Box>
            <IconButton size="large" color="inherit" href="https://twitter.com/weedheadsNFT" target="_blank">
                <TwitterIcon />
            </IconButton>
            <IconButton size="large" color="inherit" href="https://discord.gg/weedheads" target="_blank">
                <FaDiscord />
            </IconButton>
        </Box> */}
    </div>);
}

//style for the search input
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '55ch',
        },
    },
}));