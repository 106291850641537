import fetch from 'isomorphic-unfetch'
import {RPC_URL, PROGRAM_ID} from './config'

export async function getMintIds(accountPubKey: String) {
    try {
        var mintIdsArray = new Array();

        const data = {
            "jsonrpc": "2.0",
            "id": 1,
            "method": "getTokenAccountsByOwner",
            "params": [
                accountPubKey,
                {
                    "programId": PROGRAM_ID
                },
                {
                    "encoding": "jsonParsed"
                }
            ]
        }

        var tokenAccounts = await fetch(RPC_URL, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });

        var rawAccountData = await tokenAccounts.json();

        for (let index = 0; index < rawAccountData["result"]["value"].length; index++) {
            const tokenAmount = rawAccountData["result"]["value"][index]["account"]["data"]["parsed"]["info"]["tokenAmount"];

            if (tokenAmount["amount"] == "1" && tokenAmount["decimals"] == 0) {
                mintIdsArray.push(rawAccountData["result"]["value"][index]["account"]["data"]["parsed"]["info"]["mint"])
            }
        }

        return mintIdsArray;

    } catch (error) {
        console.log(error)
    }
}
