import "../css/App.css"
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from "@mui/material/styles/useTheme";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link } from "react-router-dom";
import { useState } from 'react';
import * as _ from "lodash";
export default function NFTCard(props: any) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [imageLoading, setImageLoading] = useState(true);

  const placeholderImage = require('../assets/images/placeholder.png');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div key={props.name}>
      <div>
        <Card sx={{ maxHeight: "50vh", borderRadius: 2 }} raised={true}>
          <Link to={"/nft/" + props.mintid} style={{ textDecoration: "none" }}
            className="wh-link-card">

            {/* {imageLoading &&
              <CardMedia
                component="img"
                image={placeholderImage}
                loading="lazy"
                style={{
                  minHeight: "40vh",
                  minWidth: "40vh",
                  maxWidth: "40vh",
                  maxHeight: "40vh"
                }}>
              </CardMedia>} */}

            <CardMedia
              component="img"
              image={props.url}
              style={{
                minHeight: "40vh",
                minWidth: "40vh",
                maxWidth: "40vh",
                maxHeight: "40vh",
                // backgroundImage:placeholderImage,
                opacity:imageLoading?0:1,
              }}
              placeholder={placeholderImage}
              onLoad={() => {setImageLoading(false);}}
              loading="lazy"
            />
          </Link>
          <CardContent>
            <div style={{ display: 'flex', justifyContent: "space-between" }}>
              <Typography gutterBottom variant="h6" component="div" >
                {props.name}
              </Typography>
              <div onClick={handleClickOpen} className="info-style">
                <span className="fa fa-info"></span>
              </div>
            </div>
          </CardContent>
        </Card>
      </div >
      {/* START dialog */}
      <div>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title">
          <DialogTitle id="responsive-dialog-title">
            {props.name}
          </DialogTitle>
          <DialogContent>
            <div style={{ paddingBottom: "1%" }}>
              {/* <button className="rarity-pill">
                <span className="rarity-pill-text">Rarity Score :</span> {rarityScore(props.attributes).toFixed(2)}
              </button> */}

              <button className="rarity-pill" style={{background:get_rank(props.mintid).color, fontWeight:"800"}}>
                  <span className="rarity-pill-text"></span> {get_rank(props.mintid).type}
              </button>
              <button className="rarity-pill">
                  <span className="rarity-pill-text">Rank:</span> {get_rank(props.mintid).rank}
              </button>
              <button className="rarity-pill" style={{background:"#757575"}}>
                  <span className="rarity-pill-text">Rarity Score :</span> {rarityScore(props.attributes).toFixed(2)}
              </button>
            </div>
            <TraitTable attributes={props.attributes} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      {/* END DIALOG */}
    </div>
  );
}

export function TraitTable(props: any) {
  return (<TableContainer component={Paper}>
    <Table aria-label="simple table">
      <TableHead >
        <TableRow >
          <TableCell className="wh-top-cell">Traits</TableCell>
          <TableCell className="wh-top-cell" align="right" style={{ textAlign: "left" }}>Value</TableCell>
          <TableCell className="wh-top-cell" align="right" style={{ textAlign: "left" }}>Rarity</TableCell>
          <TableCell className="wh-top-cell" align="right" style={{ textAlign: "left" }}>%</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          props.attributes.map((row: any) => (
            <TableRow
              key={row.trait_type}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.trait_type}
              </TableCell>
              <TableCell align="right" style={{ textAlign: "left" }}>{row.value}</TableCell>
              <TableCell align="right" style={{ textAlign: "left" }}>{`${traitMapping(row.trait_type, row.value, false)}`}</TableCell>
              <TableCell align="right" style={{ textAlign: "left" }}>{`${traitMapping(row.trait_type, row.value, true)}`}</TableCell>
            </TableRow>
          ))
        }
      </TableBody>
    </Table>
  </TableContainer>)
}

export function traitMapping(traitType: String, trait: String, percentOrRatio: boolean): any { //true for percent & false for ratio
  // let traits = require("../assets/presale-rarity.json");
  let traits = require("../assets/rarity.json");

  // let traitTypeArray = traits[0]["Trait Type"][`${traitType}`];
  let traitTypeArray = traits[`${traitType}`];

  let value;

  traitTypeArray.forEach((element: any) => {
    if (element["trait"] == trait) {
      let occurrence = element["occurrence"].toString().substring(0, 2);
      if (percentOrRatio) {
        occurrence = ((parseFloat(occurrence) / 712) * 100).toString().substring(0, 5);
        value = occurrence;
      } else {
        value = occurrence + "/712";
      }
    }
  });
  return value;
}

export function rarityScore(attributes: any): any {

  // return 0;
  let sumOfTraits = 0;

  attributes.forEach((element: any) => {
    sumOfTraits = sumOfTraits + (100 / parseFloat(traitMapping(element.trait_type, element.value, true).toString()));
  });

  return (sumOfTraits);
}

export function get_rank(mint:any):any{
  const all_rank = require("../assets/wh-mint-ranks.json");
  let nft = _.find(all_rank, _.matchesProperty('mint', mint));
  
  var type = ""
  var color = "#ffab00"
  const single_rank = nft.rank
  if(single_rank<=100){
    type = "Legendary"
    color="#9c27b0"
  }
  else if(single_rank >100 && single_rank <=250){
    type = "Epic"
    color="#e91e63"

  }
  
  else if(single_rank >250 && single_rank <=450){
    type = "Rare"
    color="#f44336"

  }
  
  else if(single_rank >450 && single_rank <=600){
    type = "Uncommon"
    color="#3f51b5"

  }
  else{
    type = "Common"
    color="#2196f3"

  }
  return {'rank':nft.rank,'type':type.toUpperCase(),"color":color}


}

/**
 * 
 * 1-100 Legendary
 * 100-250 Epic
 * 250-450 Rare
 * 450-600 Uncommon
 * 600-711 Common
 */

// NFT ‘A’ is has Trait ‘X’ & ‘Y’
// Trait X : 20%
// Trait Y: 40%

// Rarity Score of X will be : 1/20% that is 1/.2
// Similarly rarity score of Y: 1/.4

// Rarity score of the whole NFT will be : (1/.2) + (1/.4)

// Now you will add Rarity Score Of each and every NFT from pre-sale, Let’s say number comes out to be ’N’.
// Now to calculate	profit percentage you will divide NFT score by ’N’.
// I.e : profit share of a NFT : (Rarity score / N) *10

// NOTE: Always make sure to have only 2 decimal places and if the need comes to round off always Round-down.

/*

RS = 1/ (P/100)

RS = RS1+ RS2 + .... RSn

RS = Sum-of-all-traits (1 / (Perc/100))

PROFIT = ( SUM(RS1+RS2...RSn) / n) *10

*/
