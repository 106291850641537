import { HomePage } from "./home_page";
import PrimarySearchAppBar from "./nftlist_header_bar";
import WhFooter from "./wh_footer";
import "../css/App.css"
import { useState } from 'react';

function Home() {

    const [pubkey, setPubkey] = useState("");
    return (
        <div className="App">
            <div className="wh-header">
                <PrimarySearchAppBar setPubkey={setPubkey} />
            </div>
            <div>
                <HomePage />
            </div>
            <div>
                <WhFooter />
            </div>
        </div>
    );
}

export default Home;
