import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import KeyIcon from '@mui/icons-material/Key';
import { Button } from '@mui/material';
import { toast } from "react-toastify";
import { send } from 'process';
import { pubkeyValidity } from '../nftdata/mintdata';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

export default function PrimarySearchAppBar(props: any) {

    // const sendValue = () => {
    //     let searchValue: any = document.querySelector("#search input");
    //     props.setPubkey(searchValue["value"])
    // }

    const handleKeyPress = (event: any) => {
        if (event.key == 'Enter') {
            // sendValue();
            NavigateToNFTList("search",navigate);
        }
    }

    const weedheadsLogo = require('../assets/images/weedheads-logo.png');
    let navigate = useNavigate();

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar className="wh-header" position="static">
                <Toolbar>
                    <Link to={"/"} style={{ textDecoration: "none" }}>
                        <Box sx={{ display: { md: 'flex' } }}>
                            <img src={weedheadsLogo} alt="Logo" height={30} />
                        </Box>
                    </Link>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} />
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <Search id='search'>
                            <div className='wh-key-icon'>
                                <KeyIcon />
                            </div>
                            <StyledInputBase
                                placeholder="Wallet Public Address"
                                inputProps={{ 'aria-label': 'search' }}
                                onKeyDown={(e) => {
                                    handleKeyPress(e);
                                }}
                            />
                        </Search>
                    </Box>
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <SearchIconStyle onClick={() => { NavigateToNFTList("search",navigate); }}>
                            <div className='wh-search-icon'>
                                <SearchIcon />
                            </div>
                        </SearchIconStyle>
                    </Box>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: { md: 'flex' } }}>
                        <Button variant="contained" className='wh-button' onClick={() => {
                            navigator.clipboard.writeText(window.location.href);
                            toast("Sharing URL copied to clipboard");
                            console.log(window.location.host);
                        }
                        }>Share</Button>
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    );
}

export function NavigateToNFTList(queryId: String,navigate:any) {
    console.log("above useNav")
    console.log("below useNav")
    let searchValue: any = document.querySelector(`#${queryId} input`);
    if (searchValue["value"].length >= 20) {
        pubkeyValidity(searchValue["value"]).then((data: any) => {
            if (data) {
                navigate(`/${searchValue["value"]}`);
                // window.open(window.location.href + searchValue["value"], "_self");
            } else {
                toast("Public Address is not valid")
            }
        });
    } else {
        toast("Invalid Wallet public address")
    }
}

//style for the search bar
export const Search = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.4),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.6),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

export const SearchIconStyle = styled('div')(({ theme }) => ({
    padding: theme.spacing(0.9, 2),
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(-7),
        width: 'auto',
    },
}));

//style for the search input
export const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '55ch',
            '&:focus': {
                width: '60ch',
            },
        },
    },
}));