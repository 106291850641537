import "../css/App.css"
import NFTCard from "./nftcard";
import Grid from '@mui/material/Grid';
import { iteratingMintIds } from "../nftdata/mintdata";
import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import PrimarySearchAppBar from "./nftlist_header_bar";
import WhFooter from "./wh_footer";
import {traitMapping, rarityScore} from "./nftcard";

// PUBKEY - ECDkKDQhJ1Pjx6AMSnrmn5nFLZsAXhD9zjSiXyHzXVmH

//The total NFT percentage is "X"
//
// You have "F" percentage of profit share in NFTEEES platform
//
// NOTE : the percentage is calculated using the rarity and the total number of NFTs you own.
// This might change after the release of public mint as more arts/NFTs can have similar traits or variations

// to make nftcard list for multiple nft cards
export default function NFTList() {

    let { publickey } = useParams();
    const [success, setSuccess] = useState(0);
    const [nftdata, setData] = useState([{}]);
    const CONTENT_LOADING = 0, CONTENT_LOADED = 1, ERROR = 3;
    let ProfitShareVal = 0;

    useEffect(() => {
        if ((success != CONTENT_LOADED && success != ERROR)) {
            nftIteration(publickey);
        }
    })

    const error404 = require('../assets/images/404.png');

    function nftIteration(pubKey: any) {//for loading the data
        iteratingMintIds(pubKey).then((data: any) => {
            // need to check if data exist or not 
            // if exist check for  network errors etc
            if (data.length > 0) {
                setData(data)
                setSuccess(CONTENT_LOADED)
            } else {
                if (data[0] == "invalid pubkey") {
                    toast("Invalid pubkey")
                    setSuccess(ERROR)
                } else {
                    toast("No Weedhead NFT's")
                    setSuccess(ERROR)
                }
            }
        });
    }

    let listItems = nftdata.map((data: any) =>
        <Grid key={data["name"]} item>
            <NFTCard key={data["name"]} name={data["name"]} url={data["url"]} attributes={data["attributes"]} mintid={data["mintid"]} />
        </Grid>
    );

    function profitShare () {
        nftdata.map((data: any) => {
            ProfitShareVal += rarityScore(data.attributes)
        })
        ProfitShareVal /= 5502.915539058343
    }

    if (success == CONTENT_LOADED) {
        profitShare()
        return (
            <div className="App">
                <PrimarySearchAppBar />
                <div className="wh-nft-list">
                    <div className="wh-nft-list-text">
                        <Typography fontSize={30} fontWeight={700} fontFamily={"sans-serif"} textAlign={"center"}>
                            Your
                            <span className="wh-gradient-pink-blue"> Weedheads </span>
                            NFTs
                        </Typography>
                        {/* <Typography fontSize={20} fontWeight={500} fontFamily={"sans-serif"} textAlign={"center"}>
                            You have {ProfitShareVal.toFixed(4)}% of profit share in NFTease Platform *
                        </Typography> */}
                    </div>
                    <Grid container rowSpacing={6} spacing={6} alignItems='center'
                        justifyContent='center'
                        className="wh-grid">
                        {listItems}
                    </Grid>
                </div>
                <div className="wh-nft-list-text">
                    {/* <Typography fontSize={12} fontWeight={500} fontFamily={"sans-serif"} textAlign={"center"}>
                        Note* The percentage is calculated using the rarity and the total number of NFTs you own.
                        This might change after the release of public mint as more NFTs can have similar traits or variations
                    </Typography> */}

                    {/* <Typography fontSize={12} fontWeight={500} fontFamily={"sans-serif"} textAlign={"center"}>
                        Note* The percentage is calculated using the rarity and the total number of NFTs you own.
                        This might change after the release of public mint as more NFTs can have similar traits or variations
                    </Typography> */}

                </div>
                <WhFooter />
            </div>
        );
    } else if (success == CONTENT_LOADING) {
        return (
            <div className="App">
                <PrimarySearchAppBar />
                <div style={{ height: "100vh" }}>
                    {/* <img src={WeedHeadsBackGImage} height="100%" width="100%" /> */}
                    <Box sx={{
                        display: 'flex',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                    }}>
                        <div><CircularProgress size={50} /></div>
                    </Box>
                </div >
                <WhFooter />
            </div>
        );
    } else {
        return (
            <div className="App">
                <PrimarySearchAppBar />
                <div
                    style={{ height: "100vh" }}>
                    <img src={error404} alt="error 404" />
                </div>
                <WhFooter />
            </div>
        );
    }
}

export const MemoNFTList = React.memo(NFTList);
