import { decodeMetadata } from './utils'
import { PublicKey } from '@solana/web3.js'
import fetch from 'isomorphic-unfetch'
import { getMintIds } from './mintids';
import { Buffer } from 'buffer';
import { META_PUBKEY, RPC_URL } from './config';
const web3 = require("@solana/web3.js");


const METADATA_PUBKEY = new PublicKey(META_PUBKEY);

export async function getNft(mintKey: any) {
	try {
		// input mint here
		let address = new PublicKey(mintKey)
		let [pda, bump] = await PublicKey.findProgramAddress([
			Buffer.from("metadata"),
			METADATA_PUBKEY.toBuffer(),
			new PublicKey(address).toBuffer(),
		], METADATA_PUBKEY)

		const data = {
			"jsonrpc": "2.0",
			"id": 1,
			"method": "getAccountInfo",
			"params": [
				pda.toBase58(),
				{
					"encoding": "base64"
				}
			]
		}

		var mintPubkey = await fetch(RPC_URL, {
			method: "POST",
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(data),
		});

		var rawAccountData = await mintPubkey.json();

		let buf = Buffer.from(rawAccountData["result"]["value"]["data"][0], 'base64')
		let m = decodeMetadata(buf)

		let uriFetch = await fetch(m.data.uri)

		var rawUriData = await uriFetch.json();

		try {
			return { "name": rawUriData["name"], "url": rawUriData["image"], "attributes": rawUriData["attributes"], "mintid": mintKey }
		} catch (error) {
		}

	} catch (e) {
		console.log(e)
	}
}

export async function iteratingMintIds(accountPubKey: String) {

	//end of validilty check
	let idsOfMint: any[] = []
	idsOfMint = idsOfMint.concat(await getMintIds(accountPubKey))

	let nfts: any[] = [];
	// let knownTokens = require("../assets/preale-mint-tokens.json");
	let knownTokens = require("../assets/all-mints.json");

	console.log(knownTokens)
	//foreach doesn't work
	if (idsOfMint.length > 0) {
		for (let i = 0; i < idsOfMint.length; i++) {
			if (knownTokens.includes(idsOfMint[i])) {
				let j = await getNft(idsOfMint[i])
				nfts.push(j)
			}
		}
		return nfts;
	}
	else {
		return ["nothing found"]
	}

}

export async function pubkeyValidity(accountPubKey: String) {
	//for checking the validity of pubkey
	//start of validilty check

	try{

		let pubkey = new PublicKey(accountPubKey);		
		let isValid = PublicKey.isOnCurve(pubkey.toBytes());
		
		return isValid;
	}
	catch(error:any){
		return false;
	}

	let data = {
		"jsonrpc": "2.0",
		"id": 1,
		"method": "getAccountInfo",
		"params": [
			accountPubKey,
			{
				"encoding": "base58"
			}
		]
	}

	var tokenAccounts = await fetch(RPC_URL, {
		method: "POST",
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(data),
	});

	var rawAccountData = await tokenAccounts.json();

	let valid;//bool for the validity of the pubkey

	if (rawAccountData.result.value == null) {
		valid = false;
	} else {
		valid = true;
	}
	return valid;
}
// iteratingMintIds("ECDkKDQhJ1Pjx6AMSnrmn5nFLZsAXhD9zjSiXyHzXVmH").then((d) => {
// 	console.log(d)
// 	console.log(d.forEach(e => console.log(e["attributes"])))
// });