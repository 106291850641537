import React from "react";
import { Container, IconButton, Grid, Box, Link } from '@mui/material';
import { FaDiscord } from "react-icons/fa";
import TwitterIcon from '@mui/icons-material/Twitter';

export default function WhFooter() {
    return <footer>
        <Box className="wh-footer">
            <Container maxWidth="lg">
                <Grid direction="row" justifyContent="space-around">
                    <Grid item>
                        <Box >
                            Copyright © Weedheads NFT, 2022
                        
                        </Box>
                    </Grid>
                    {/* <Grid item>
                        <Box>
                            <IconButton size="large" color="inherit" href="https://twitter.com/weedheadsNFT" target="_blank">
                                <TwitterIcon />
                            </IconButton>
                            <IconButton size="large" color="inherit" href="https://discord.com/invite/c3E4tHPUHX" target="_blank">
                                <FaDiscord />
                            </IconButton>
                        </Box>
                    </Grid> */}
                </Grid>
            </Container>
        </Box>
    </footer>
}
