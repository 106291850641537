import NFTList from "./components/nftlist";
import MemoSingleNFT from "./components/single_nft"
// import React from "react";
import Home from "./components/home";
import { BrowserRouter as Router, Routes, Route, useParams} from 'react-router-dom';

function App() {
    return (
            <Routes>
                {/*<Route   path="/" element={<UserPublicId/>}>*/}
                {/*    /!* <UserPublicId /> *!/*/}
                {/*</Route>*/}
                <Route path="/" element={<Home/>}>
                </Route>
                <Route path="/:publickey" element = {<NFTList/>}>
                </Route>
                <Route path="/nft/:mintid" element={<MemoSingleNFT/>}  >
                </Route>
            
            </Routes>
    //   <div></div>
    );
}

export default App;
