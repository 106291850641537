import "../css/App.css"
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardMedia, Typography, CardContent, CircularProgress, Box, AppBar, Toolbar, Button, Skeleton } from "@mui/material";
import { getNft } from "../nftdata/mintdata";
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Grid from '@mui/material/Grid';
import WhFooter from "./wh_footer";
import { useEffect } from 'react';
import { get_rank, rarityScore, TraitTable } from "./nftcard";
import { Link } from "react-router-dom";
import { red } from "@mui/material/colors";

toast.configure()

//for showing whole details of a NFT
export default function SingleNFT(props: any) {
    let { mintid } = useParams();
    const [success, setSuccess] = useState(false);
    const [name, setName] = useState("Loading.....");
    const [attributes, setAttributes] = useState([])
    const [url, setUrl] = useState("Loading....");
    const [imageLoading,setImageLoading] = useState(true);
    const weedheadsLogo = require('../assets/images/weedheads-logo.png');
    const placeholderImage = require('../assets/images/placeholder.png');

    let navigate = useNavigate();

    rarityScore(attributes);

    useEffect(() => {

        if (!success) {
            getNft(mintid).then((data: any) => {
                setName(data["name"]);
                setAttributes(data["attributes"]);
                setUrl(data["url"]);
                setSuccess(true)
            });
        }
    })


    if (success) {
        return (
            <div className="single-nft">
                <div className="wh-header">
                    <Box sx={{ flexGrow: 1 }}>
                        <AppBar position="static" color='transparent'>
                            <Toolbar>
                                <Link to={"/"} style={{ textDecoration: "none" }}>

                                    <img src={weedheadsLogo} alt="Logo" height={30} />
                                </Link>
                                <Box sx={{ flexGrow: 1 }} />
                                <Box sx={{ display: { md: 'flex' } }}>
                                    <Button variant="contained" className='wh-button' onClick={() => {
                                        navigator.clipboard.writeText(window.location.href);
                                        toast('Sharing URL copied to clipboard')
                                    }}>Share</Button>
                                </Box>
                            </Toolbar>
                        </AppBar>
                    </Box>
                </div>

                {/* <div className="single-nft-top-wrapper"> */}
                <Box sx={{ margin:"2% 5%"}}>
                    {/* breadcrumbs start */}
                    <div className="wh-single-nft-breadcrumb" style={{ display: "flex",marginBottom:"30px" }} >
                        <a style={{ textDecoration: "none" }} onClick={() => { navigate(-1) }}>
                            <p className="wh-light-text">{"Home / "}</p>
                        </a>
                        <p className="wh-light-text" style={{ paddingLeft: "5px", opacity: "0.5", cursor: "pointer" }}>{name}</p>
                    </div>
                    {/* END breadcrumbs  */}

                    {/* WRAPPER START */}
                    {/* <div > */}
                    <Grid container spacing={2}>

                        <Grid item md={6} xs={12} sm={12}>
                            <div >
                                <Card sx={{ borderRadius: 2 }} raised={true}>

                                    { imageLoading &&
                                        <CardMedia 
                                         component="img"
                                         image={placeholderImage}>
                                        </CardMedia>
                                    }

                                <CardMedia
                                    component="img"
                                    alt={props.name}
                                    image={url}
                                    loading="lazy"
                                    onLoad={()=>setImageLoading(false)}
                                    onProgress={()=>console.log('progress')}
                                    style={{display:imageLoading?"none":"flex"}}
                                />     
                                </Card>
                            </div>

                        </Grid>

                        <Grid item md={6} xs={12} sm={12} >
                            {/* START TABLE*/}
                                <Card sx={{ borderRadius: 2,maxWidth:"100vh" }} raised={true}>
                                    <CardContent>
                                        <Typography gutterBottom variant="h6" component="div" fontWeight={600} >
                                            {name}
                                        </Typography>
                                        <button className="rarity-pill" style={{background:get_rank(mintid).color, fontWeight:"800"}}>
                                            <span className="rarity-pill-text"></span> {get_rank(mintid).type}
                                        </button>
                                        <button className="rarity-pill">
                                            <span className="rarity-pill-text">Rank:</span> {get_rank(mintid).rank}
                                        </button>
                                        <button className="rarity-pill" style={{background:"#757575"}}>
                                            <span className="rarity-pill-text">Rarity Score :</span> {rarityScore(attributes).toFixed(2)}
                                        </button>

                                    <TraitTable attributes={attributes} />
                                    </CardContent>
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div" fontWeight={600} >
                                            {"Token"}
                                        </Typography>
                                        <Typography gutterBottom variant="subtitle2" component="div" style={{
                                            "overflowWrap": "break-word"
                                        }}>
                                            <a href={"https://explorer.solana.com/address/" + mintid} target="_blank">
                                                {mintid}
                                            </a>
                                        </Typography>
                                    </CardContent>
                                </Card>
                            {/* END TABLE*/}
                        </Grid>
                    </Grid>
                    {/* </div> */}
                    {/* Wrapper end */}
                    </Box>
                {/* </div> */}
                <WhFooter />
            </div>
        );
    }
    else {
        //for circular progess when data is not loaded
        return (
            <div style={{ backgroundColor: "#082032", height: "100vh" }}>

                <Box sx={{
                    display: 'flex',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                }}>
                    <CircularProgress size={50} />
                </Box >
            </div >
        );
    }

}
//for caching
export const MemoSingleNFT = React.memo(SingleNFT);